* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-family: sans-serif;
  font-size: 16px;
}

nav {
  background: #222;
  padding: 0 15px;
}
a {
  color: white;
  text-decoration: none;
}
.menu,
.submenu {   
  list-style-type: none;
}
.logo {
  font-size: 20px;
  padding: 7.5px 10px 7.5px 0;
}
.item {
  padding: 10px;
}
.item.button {
  padding: 9px 5px;
}
.item:not(.button) a:hover,
.item a:hover::after {
  color: #ccc;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Mobile menu */
.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.menu li a {
  display: block;
  padding: 15px 5px;
}
.menu li.subitem a {
  padding: 15px;
}
.toggle {
  order: 1;
  font-size: 20px;
}
.item.button {
  order: 2;
}
.item {
  order: 3;
  width: 100%;
  text-align: center;
  display: none;
}
.active .item {
  display: block;
}
.button.secondary { /* divider between buttons and menu links */
  border-bottom: 1px #444 solid;
}

.footer {
  margin-top: 2rem;
}

/* Tablet menu */
@media all and (min-width: 700px) {
  .menu {
      justify-content: center;
  }
  .logo {
      flex: 1;
  }
  .item.button {
      width: auto;
      order: 1;
      display: block;
  }
  .toggle {
      flex: 1;
      text-align: right;
      order: 2;
  }
  /* Button up from tablet screen */
  .menu li.button a {
      padding: 10px 15px;
      margin: 5px 0;
  }
  .button a {
      background: #0080ff;
      border: 1px royalblue solid;
  }
  .button.secondary {
      border: 0;
  }
  .button.secondary a {
      background: transparent;
      border: 1px #0080ff solid;  
  }
  .button a:hover {
      text-decoration: none;
  }
  .button:not(.secondary) a:hover {
      background: royalblue;
      border-color: darkblue;
  }
}

/* Desktop menu */
@media all and (min-width: 1080px) {
  .menu {
      align-items: flex-start;     
      flex-wrap: nowrap;
      background: none;
  }
  .logo {
      order: 0;
  }
  .item {
      order: 1;
      position: relative;
      display: block; 
      width: auto;
  }
  .button {
      order: 2;
  }
  .submenu-active .submenu {
      display: block;
      position: absolute;
      left: 0;
      top: 68px;
      background: #111;
  }
  .toggle {
      display: none;
  }
  .submenu-active {
      border-radius: 0;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


